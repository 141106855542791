var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajout des séances automatiquement"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées nécéssaire ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre de séances par jour","label-for":"nb seances"}},[_c('validation-provider',{attrs:{"name":"nb seances","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.nbSeances,"placeholder":"Nombre de séances par jour"},model:{value:(_vm.seances.nb_seance),callback:function ($$v) {_vm.$set(_vm.seances, "nb_seance", $$v)},expression:"seances.nb_seance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Catégorie Séance","label-for":"Catégorie Séance"}},[_c('validation-provider',{attrs:{"name":"Catégorie Séance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.categories,"placeholder":"Catégorie Séance"},model:{value:(_vm.seances.category),callback:function ($$v) {_vm.$set(_vm.seances, "category", $$v)},expression:"seances.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Heure Début","label-for":"Heure Début"}},[_c('b-time',{staticStyle:{"min-width":"760px"},attrs:{"id":"ex-disabled-readonly"},model:{value:(_vm.seances.start_time),callback:function ($$v) {_vm.$set(_vm.seances, "start_time", $$v)},expression:"seances.start_time"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Durée séance (Heure)","label-for":"Durée séance"}},[_c('validation-provider',{attrs:{"name":"Durée séance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.times,"placeholder":"Durée séance"},model:{value:(_vm.seances.time_seance),callback:function ($$v) {_vm.$set(_vm.seances, "time_seance", $$v)},expression:"seances.time_seance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pause entre séances (Minute)","label-for":"Pause entre séances"}},[_c('validation-provider',{attrs:{"name":"Pause entre séances","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.paussesBetween,"placeholder":"Pause entre séances"},model:{value:(_vm.seances.pausse_between_seance),callback:function ($$v) {_vm.$set(_vm.seances, "pausse_between_seance", $$v)},expression:"seances.pausse_between_seance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pause après la séance n°","label-for":"Pause après la séance n°"}},[_c('validation-provider',{attrs:{"name":"Pause après la séance n°","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.paussesAfter,"placeholder":"Pause après la séance n°"},model:{value:(_vm.seances.pausse_after_seance),callback:function ($$v) {_vm.$set(_vm.seances, "pausse_after_seance", $$v)},expression:"seances.pausse_after_seance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addSeances.apply(null, arguments)}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }