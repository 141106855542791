<template>
  <b-card-code title="Ajout des séances automatiquement">
    <b-card-text>
      <span>Veuillez saisir les coordonnées nécéssaire </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nombre de séances par jour"
              label-for="nb seances"
            >
              <validation-provider
                #default="{ errors }"
                name="nb seances"
                rules="required"
              >
                <v-select
                  v-model="seances.nb_seance"
                  :clearable="false"
                  :options="nbSeances"
                  placeholder="Nombre de séances par jour"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Catégorie Séance"
              label-for="Catégorie Séance"
            >
              <validation-provider
                #default="{ errors }"
                name="Catégorie Séance"
                rules="required"
              >
                <v-select
                  v-model="seances.category"
                  :clearable="false"
                  :options="categories"
                  placeholder="Catégorie Séance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group

              label="Heure Début"
              label-for="Heure Début"
            >
              <b-time
                id="ex-disabled-readonly"
                v-model="seances.start_time"
                style="min-width: 760px;"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Durée séance (Heure)"
              label-for="Durée séance"
            >
              <validation-provider
                #default="{ errors }"
                name="Durée séance"
                rules="required"
              >
                <v-select
                  v-model="seances.time_seance"
                  :clearable="false"
                  :options="times"
                  placeholder="Durée séance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Pause entre séances (Minute)"
              label-for="Pause entre séances"
            >
              <validation-provider
                #default="{ errors }"
                name="Pause entre séances"
                rules="required"
              >
                <v-select
                  v-model="seances.pausse_between_seance"
                  :clearable="false"
                  :options="paussesBetween"
                  placeholder="Pause entre séances"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Pause après la séance n°"
              label-for="Pause après la séance n°"
            >
              <validation-provider
                #default="{ errors }"
                name="Pause après la séance n°"
                rules="required"
              >
                <v-select
                  v-model="seances.pausse_after_seance"
                  :clearable="false"
                  :options="paussesAfter"
                  placeholder="Pause après la séance n°"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addSeances"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import storeAuth from '@/store/store'

import {
  // BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BTime/*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    // BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BTime,
  },
  data() {
    return {
      password: '',
      seances: {
        academic_year: '',
        nb_seance: '',
        category: '',
        start_time: '',
        time_seance: '',
        pausse_between_seance: 0,
        pausse_after_seance: '',
      },
      required,
      nbSeances: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      categories: ['cour', 'examen', 'ds'],
      times: [1, 1.5, 2, 2.5, 3],
      paussesBetween: [5, 10, 15],
      paussesAfter: [1, 2, 3, 4, 5, 6, 7, 8],
    }
  },
  created() {
    this.seances.academic_year = storeAuth.state.currentAcademicYear.id
  },
  methods: {
    async addSeances() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/seances/generate_seances/',
                this.seances,
              )
            this.$router.push('/seances')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Séances ajoutés avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
